<template>
    <div class="cricket_matches_tabs">
        <ul>
            <li :class="(this.$route.params.type == 'all') ? 'selected' : ''">
            <router-link :to="{ name: 'cricket_listing', params: { type: 'all', date:  selectedDate } }">
                <span class="all_tournament_tab">{{ $t("cric.All Matches") }}
                    <!-- <samp v-if="counts">
                        ({{counts.all}})
                    </samp> -->
                </span>
            </router-link>
            </li>
            <!-- <li :class="(this.$route.params.type == 'live') ? 'selected' : ''">
            <router-link :to="{ name: 'cricket_listing', params: { type: 'live', date:  selectedDate } }">
                <span>{{ $t("cric.Live") }}
                    <samp v-if="counts">
                        ({{counts.live}})
                    </samp>
                </span>
            </router-link>
            </li> -->
            <!-- <li :class="(this.$route.params.type == 'finished') ? 'selected' : ''">
            <router-link :to="{ name: 'cricket_listing', params: { type: 'finished', date:  selectedDate } }">
                <span>{{ $t("cric.Finished") }}
                    <samp v-if="counts">
                        ({{counts.finished}})
                    </samp>
                </span>
            </router-link>
            </li> -->
            <!-- <li :class="(this.$route.params.type == 'upcoming') ? 'selected' : ''">
            <router-link :to="{ name: 'cricket_listing', params: { type: 'upcoming', date:  selectedDate } }">
                <span>
                        {{ $t("cric.Upcoming") }}
                    <samp v-if="counts">
                        ({{counts.upcoming}})
                    </samp>
                </span>
            </router-link>
            </li> -->
            <li :class="{ selected: $route.name == 'cricket_standings' }">
                <router-link :to="{ name: 'cricket_standings'}">
                    <span>
                      {{ $t("cric.Standings") }} </span>
                </router-link>
            </li>
            <!-- <li>
                <router-link :to="{ name: 'cricket_teams'}">
                    <span>
                      {{ $t("Teams") }} </span>
                </router-link>
            </li> -->
            <li :class="{ selected: $route.name == 'myMatches' }">
                <router-link :to="{ name: 'myMatches', params: { date:  selectedDate }}">
                    <span>
                        {{ $t("cric.My Matches") }} <!-- ({{ $store.getters.favouriteMatches.length }}) -->
                    </span>
                </router-link>
            </li>

    </ul>
</div>
</template>

<script>
  import moment from 'moment';
  export default {
    name: "listing-tab",
    data() {
      return {
        counts: '',
        selectedDate: moment().format('YYYY-MM-DD'),
      }
    },
    serverPrefetch() {
      this.selectedDate = (this.$route.params.date == undefined) ? moment().format('YYYY-MM-DD') : this.$route.params.date;
        // return this.getLiveCount();
    },
    mounted() {
      this.selectedDate = (this.$route.params.date == undefined) ? moment().format('YYYY-MM-DD') : this.$route.params.date;
        // this.getLiveCount();
    },
    methods: {
      getLiveCount() {
        return this.$axios.get(this.$MATCH_COUNT_API + '/' + this.selectedDate)
          .then(response => {
            if (response.data.result) {
              this.counts = response.data.result.count;
            }
          })
          .catch(error => console.log(error))
      },
    }
  }
</script>

