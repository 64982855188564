<template>

    <div class="container prefix-live-center mt-4">
        <div class="all_matches_content">
            <div class="sabc_lc_tabs_date">
                <ListingTabs :key="$route.params.date+'-tabs'"></ListingTabs>
                <DateSlider :key="$route.params.date+'-slider'"></DateSlider>
            </div>
            <div class="text-center" v-if="!is_data_ready">
                <div class="spinner-border" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
            <div v-else-if="standings.length == 0 && is_data_ready">
                Not standings found
            </div>

            <div class="standing-table cr-global-table" v-else v-for="tournament in standings" :key="tournament.id">
                <ul>
                    <li class="tournament-tab"> {{tournament.name}} </li>
                </ul>
                <table>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Teams</th>
                            <th class="only-hide-mobile">M</th>
                            <th class="only-hide-mobile">W</th>
                            <th class="only-hide-mobile">L</th>
                            <th class="only-hide-mobile">D</th>
                            <th>PTS</th>
                            <th>NET RR</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(team, index) in tournament.cricTournamentStanding" :key="index+'_'+tournament.id+'_'+team.team_id"> <!-- class="highlight"-->
                            <td>{{index+1}}</td>
                            <td>{{team.team_name}}</td>
                            <td class="only-hide-mobile">{{team.matches}}</td>
                            <td class="only-hide-mobile">{{team.won}}</td>
                            <td class="only-hide-mobile">{{team.lost}}</td>
                            <td class="only-hide-mobile">{{team.tied}}</td>
                            <td>{{team.points}}</td>
                            <td>{{team.net_RR}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>

</template>

<script>
    import DateSlider from './Common/date-slider';
    import ListingTabs from './Common/match-listing-tabs';
    import moment from "moment";
    export default {
        name: "cricket-standings",
        data() {
            return {
                standings : [],
            };
        },
        components: {
            DateSlider, ListingTabs
        },
        mounted() {
            this.getStandings();
        },
        serverPrefetch() {
            return this.getStandings();
        },
        metaInfo() {
            if (this.$store.state.siteSetting) {

                let ss = this.$store.state.siteSetting;
                var replacements = {
                    '%DATE%': moment(new Date()).format("YYYY-MM-DD"),
                    '%TAB%': 'Standings',
                };

                let title = this.$getSafe(() => this.$replacePlaceHolders(ss.cricket_listing_meta_title,replacements));
                let description = this.$getSafe(() => this.$replacePlaceHolders(ss.cricket_listing_meta_description,replacements));
                let keywords = this.$getSafe(() => ss.cricket_listing_meta_keywords);

                return {
                    title: title,
                    description: description,
                    meta: this.$getMetaTags(title, description, keywords, ''),
                    link: [
                        {rel: 'canonical', href: this.$toFullPath(this.$route.fullPath)}],
                };
            }
        },
        methods: {
            getStandings() {
              this.is_data_ready = false;
                return this.$axios
                    .get(this.$GET_STANDINGS)
                    .then(response => {
                        this.standings = response.data.result.standings;
                        this.is_data_ready = true;
                    })
            }
        }
    }
</script>
<style lang="scss">
@import '~@/assets/scss/cricket.scss';
</style>